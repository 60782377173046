<style scoped>
.banner {
  background-image: url("../assets/images/webp/bg.jpg");
}
</style>
<template>
  <Animation3 />

  <div id="jobs" class="page">
    <div class="banner">
      <div class="container intro-container">
        <div class="intro-text-wrapper">
          <h1 class="BannerHeading" v-html="content.Mainheading"></h1>
          <!--<h1 class="BannerHeading" v-else>{{ err ? err : content.Mainheading }}</h1>-->
          <p>
            {{ content.Description }}
          </p>
          <p>
            {{ content.Description1 }}
          </p>
          <p>
            {{ content.Description2 }}
          </p>
          <a
            href="#careers"
            v-smooth-scroll="{ duration: 1000 }"
            class="btn btn-default exploreBtn"
          >
            {{ content.ExploreBtn }}
            <span class="iconfa">
              <i class="fa fa-chevron-down"></i>
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="quickCards">
      <div class="container intro-container">
        <a-row>
          <a-col :span="8">
            <div class="cardsMain">
              <div class="v-quick__graphic"></div>
              <div class="cardsCont">
                <h2>{{ content.LifeText }} <Arrow /></h2>
                <p>{{ content.LifeTextp }}</p>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="cardsMain card2">
              <div class="v-quick__graphic"></div>
              <div class="cardsCont">
                <h2>{{ content.ValuesText }} <Arrow /></h2>
                <p>{{ content.ValuesTextp }}</p>
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="cardsMain card3">
              <div class="v-quick__graphic"></div>
              <div class="cardsCont">
                <h2>{{ content.PerksText }} <Arrow /></h2>
                <p>{{ content.PerksTextp }}</p>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>

    <span v-if="loading == true" class="flex justify-center">
      <PulseLoader />
    </span>
    <section id="Roles">
      <div class="container">
        <div class="decoration-block"></div>
        <div class="mainRoles">
          <h3>{{ content.OpenRoles }}</h3>
          <h2>{{ content.OpenRolesHeading }}</h2>
          <p>
            {{ content.OpenRolesText }}
          </p>
        </div>
      </div>
    </section>
    <section id="careers" class="jobs container" v-if="jobs">
      <!-- <div class="left"> -->
      <!-- <h2>{{ content.OfficiesHeading }}</h2>

        <p @click="resetOffices()">{{ content.DepartmentAllText }}</p>
        <div v-for="(office, index) in offices" :key="office.id">
          <p @click="filterOffices(office)">
            {{ this.offices[index].office.name }}
          </p>
        </div> -->

      <!-- <div class="row-gap"></div> -->
      <!-- <br /> -->
      <!-- <h2>{{ content.DepartmentsHeading }}</h2> -->

      <!-- <p @click="resetOffices()">{{ content.DepartmentAllText }}</p> -->
      <!-- <div v-for="(dept, index) in dept" :key="dept.id">
          <p :key="dept.id" @click="filterdept(dept)">
            {{
              siteLanguage == "GR"
                ? this.dept[index].dept.name_gr
                : this.dept[index].dept.name_en
            }}
          </p>
        </div> -->
      <!-- </div> -->
      <div class="right">
        <div class="cards-container">
          <div class="cardmain" v-for="job in jobs" :key="job.id">
            <h1>{{ job.name_en }}</h1>
            <div class="card" v-for="deptJob in job.jobs" :key="deptJob.id">
              <div class="careerCard">
                <span class="department">{{ deptJob.title }}</span>
                <!-- <h3 class="position" v-html="job.short_description" /> -->
                <p class="location" v-if="deptJob.office != null">
                  {{ deptJob.office.name }}
                </p>
              </div>
              <router-link
                class="jobs-name"
                :to="{ name: 'Job Details', params: { slug: deptJob.slug } }"
              >
                <button class="btn btn-alternate">
                  <Arrow />
                  <!-- {{ content.ApplyNowButtonText }} -->
                </button>
              </router-link>
            </div>
          </div>
        </div>

        <!-- <div class="bottom-pagination">

        <VueTailwindPagination
        :current="currentPage"
        :total="total"
        :per-page="perPage"
        @page-changed="currentPage = $event"
        :hideEllipsis= false

        text-before-input=""     text-after-input="Go"/>
      </div> -->
      </div>
    </section>
    <section class="positions" v-if="siteLanguage == 'EN'">
      <div class="container">
        <h2 class="pos-head">A DAY AT GAP DYNAMICS</h2>
        <div class="positions-main">
          <div>
            <Happy :activePosDescIndex="this.activePosDescIndex" />
          </div>
          <div class="pos-desc">
            <div
              class="desc-inner"
              :class="this.activePosDescIndex == 0 ? 'active' : ''"
            >
              <h2>MORNING COFFEE</h2>
              <p>
                I sip on a cup of Fruit Party coffee from my favorite roaster,
                Dark Horse, while I catch up on emails and Slack notifications.
                I check the #music channel for today’s curated beats before
                jumping into some heads-down work time
              </p>
            </div>

            <div
              class="desc-inner"
              :class="this.activePosDescIndex == 1 ? 'active' : ''"
            >
              <h2>STANDUP</h2>
              <p>
                Most people at Gap Dynamics are on one or two projects. I’m on
                two and daily standups for them are back-to-back. This is the
                one time a day when every person on a project will sync up as a
                group and make sure we’re aligned on the day ahead.
              </p>
            </div>
            <div
              class="desc-inner"
              :class="this.activePosDescIndex == 2 ? 'active' : ''"
            >
              <h2>MORNING FLOW</h2>
              <p>
                One of the projects I’m working on is a healthcare app that will
                let people better coordinate what they buy at the grocery store
                with what foods make the most sense for their health needs.With
                a global workforce, my design feedback from yesterday afternoon
                was already implemented while I was asleep and engineering just
                dropped a new alpha build and now it’s time review the changes
                ahead of my first meeting.
              </p>
            </div>
            <div
              class="desc-inner"
              :class="this.activePosDescIndex == 3 ? 'active' : ''"
            >
              <h2>LUNCH</h2>
              <p>
                I ordered in lunch today because I have a virtual lunch date
                with a new hire in our London office, he’s going to be eating
                dinner (or do they call it supper there?). I have just enough
                time after lunch to do a quick practice runthrough of a deck I’m
                going to be presenting to a client.
              </p>
            </div>
            <div
              class="desc-inner"
              :class="this.activePosDescIndex == 4 ? 'active' : ''"
            >
              <h2>CLIENT MEETING</h2>
              <p>
                I hop onto the Zoom ready to present the client with our latest
                updates.The client was super into some of the new design
                concepts and had some really interesting and challenging asks
                around speeding up some of the load times of some asset-heavy
                pages. I book some time with one of the project engineers so we
                can brainstorm solutions.
              </p>
            </div>
            <div
              class="desc-inner"
              :class="this.activePosDescIndex == 5 ? 'active' : ''"
            >
              <h2>AFTERNOON FLOW</h2>
              <p>
                Back to flow time for a few hours playing around with a new
                carousel for browsing recipes. We have a ton of content to show
                at once and I’m trying to figure out how we can present it to
                users without being too overwhelming. I jumped into a session
                with a UX designer and a PM to try and flesh out a few more
                layout concepts for browsing the recipes. Was pretty blown away
                by some of the ideas that the UX designer came to the meeting
                with so I take a second to log some praise for her in public via
                our #praise channel.
              </p>
            </div>

            <div
              class="desc-inner"
              :class="this.activePosDescIndex == 6 ? 'active' : ''"
            >
              <h2>MANAGER 1:1</h2>
              <p>
                Time for a quick 1:1 with my manager. We have to stop ourselves
                from spending the whole meeting trading dog and baby photos.
                Fortunately, we also find plenty of time to check in on the
                professional growth plan that we laid out together a few months
                ago. We come up with a plan for me to spend some of my learning
                & development budget on a technical product management classes
                to help bolster my skills and get me better aligned with
                requirements for a title bump.
              </p>
            </div>
            <div
              class="desc-inner"
              :class="this.activePosDescIndex == 7 ? 'active' : ''"
            >
              <h2>HAPPY HOUR</h2>
              <p>
                I quickly browse some of the less time sensitive Slack channels
                and notice that a crew is heading to the park tonight for one of
                those Concert In The Park things the philharmonic puts on every
                year. Time to go find my picnic blanket…
              </p>
            </div>
          </div>
        </div>
        <div class="about-work">
          <ul class="icons-list">
            <li><Gift /></li>
            <li><DollarImg /></li>
            <li><Watch /></li>
            <li><Emoji /></li>
            <li><Calendar /></li>
            <li><Plant /></li>
            <li><Aero /></li>
            <li><Light /></li>
          </ul>
          <h2>WHAT’S SPECIAL ABOUT WORKING AT GAP DYNAMICS?</h2>
          <p>
            There are 3 things that make Gap Dynamics special: our team, our
            process and our clients. We’ve got the process part sorted. But
            we’re expanding our teams quickly and looking for others to join our
            tenacious yet playful team.
          </p>
          <a
            href="#careers"
            v-smooth-scroll="{ duration: 1000 }"
            class="btn btn-default exploreBtn"
          >
            {{ content.ExploreBtn }}
            <span class="iconfa">
              <i class="fa fa-chevron-down"></i>
            </span>
          </a>
        </div>
      </div>
    </section>

    <main id="projects" class="page feature">
      <!-- <Animation3 /> -->
      <div class="container">
        <h2>{{ content.FeaturedWorkTitle }}</h2>
        <!-- {{ content.Heading1 }} -->

        <!-- <br />
          <span>
            {{ content.Heading2 }}
          </span> -->

        <!-- <p
          class=" p-project-font pt-6 pb-2 sm:mr-20 sm:pb-6 sm:text-lg lg:text-xl xl:mr-96 xl-pr-96 xl:text-2xl xl:tracking-normalss"
        >
          {{ content.Description }}
        </p> -->
      </div>
      <section class="pt-2 overflow-hidden">
        <div>
          <div
            class="
              grid
              projects-grid
              lg:grid-cols-3
              md:grid-cols-2
              grid-rows-1
              gap-0
              rotate-1
            "
          >
            <!--  Joint Store -->
            <div
              class="
                hover:bg-gray-900
                bg-gray-800
                justify-items-stretch
                z-0
                transition
                project-display
              "
              :style="{
                background:
                  'url(' +
                  require('@/assets/imgs/projects/joint-store-bg.png') +
                  ') center left',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }"
            >
              <div
                class="
                  py-12
                  pl-8
                  pb-0
                  antialiased
                  subpixel-antialiased
                  flex flex-row
                  space-x-0
                  iphone678:space-x-0
                  pixle:space-x-0
                  infinix:space-x-0
                  items-center
                "
              >
                <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                  <img
                    class="project-logo"
                    src="@/assets/imgs/projects/logo/joint-store.png"
                  />
                </span>

                <span class="lg:invisible md:invisible">
                  <ArrowSvg />
                </span>
              </div>
              <div class="img-group">
                <div>
                  <img
                    class="p-0 mb-4 joint-mob joint1"
                    :src="require('@/assets/imgs/projects/joint-web.png')"
                    alt="No Image"
                  />
                  <img
                    class="p-0 mb-4 joint-web "
                    :src="require('@/assets/imgs/projects/joint-mob.png')"
                    alt="No Image"
                  />
                </div>
              </div>
            </div>

            <!-- Screenflash -->
            <div
              class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-10 transition project-display"
              :style="{ background: '#E61A4F' }"
            >
              <div
                class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row
              items-center
            "
              >
                <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                  <img
                    class="project-logo"
                    src="@/assets/imgs/projects/logo/screenflash.png"
                  />
                </span>

                <span class="lg:invisible md:invisible">
                  <ArrowSvg />
                </span>
              </div>
              <div class="img-group">
                <img
                  class="
                p-0
                msg1
              "
                  :src="require('@/assets/imgs/projects/screen2.png')"
                  alt="No Image"
                />
                <img
                  class="
                p-0
                msg2
              "
                  :src="require('@/assets/imgs/projects/screen1.png')"
                  alt="No Image"
                />
              </div>
            </div>
            <!-- Cannabisbit -->
            <div
              class="hover:bg-red-900 project-background-auotradia justify-items-stretch z-0 transition project-display"
              :style="{
                background: 'linear-gradient(#00D1c2, #4c39fc)',
              }"
            >
              <div
                class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row              
              items-center
            "
              >
                <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                  <img
                    class="project-logo"
                    src="@/assets/imgs/projects/logo/sharebius.png"
                  />
                </span>

                <span class="lg:invisible md:invisible">
                  <ArrowSvg />
                </span>
              </div>
              <div class="img-group web-group">
                <img
                  class="
                p-0
                tonino1
              "
                  :src="require('@/assets/imgs/projects/canabis2.png')"
                  alt="No Image"
                />
                <img
                  class="
                p-0
                tonino2
              "
                  :src="require('@/assets/imgs/projects/canabis1.png')"
                  alt="No Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// import Button from "@/components/Button";
import constant from "../config/constants"; //find all the api urls, assets, images path here

import Animation3 from "@/components/Animation3";
import axios from "axios";
import { LanguageStrings } from "@/languages";
import { SeoStrings } from "@/seo";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Arrow from "@/assets/icons/arrow.svg";
import DollarImg from "@/assets/imgs/dollar.svg";
import Happy from "@/assets/imgs/happy.svg";
import Gift from "@/assets/imgs/gift-box.svg";
import Watch from "@/assets/imgs/watch.svg";
import Emoji from "@/assets/imgs/emoji.svg";
import Calendar from "@/assets/imgs/calendar.svg";
import Plant from "@/assets/imgs/plant.svg";
import Aero from "@/assets/imgs/plain.svg";
import Light from "@/assets/imgs/light.svg";

// import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'

export default {
  name: "Jobs",
  components: {
    Animation3,
    PulseLoader,
    Arrow,
    Happy,
    Gift,
    DollarImg,
    Watch,
    Emoji,
    Calendar,
    Plant,
    Aero,
    Light,
    // VueTailwindPagination
  },
  data() {
    return {
      siteLanguage: localStorage.getItem("siteLanguage"),
      jobs: null,
      dublicatejobs: [],
      err: "",
      loading: false,
      content: [],
      Seo: {},
      offices: [],
      dept: [],
      currentPage: 1,
      perPage: 5,
      total: 20,
      activePosDescIndex: 0,
      posDescItems: [],
    };
  },
  methods: {
    resetOffices() {
      this.jobs = this.dublicatejobs;
    },
    filterOffices(item) {
      this.jobs = this.dublicatejobs.filter(
        (sin) => sin.office.name === item.office.name
      );
    },

    filterdept(item) {
      this.jobs =
        localStorage.getItem("siteLanguage") === "EN"
          ? this.dublicatejobs.filter(
              (sin) => sin.department.name_en === item.dept.name_en
            )
          : this.dublicatejobs.filter(
              (sin) => sin.department.name_gr === item.dept.name_gr
            );

      // this.jobs = this.dublicatejobs.filter(
      //   (sin) => sin.department.name === item.dept.name
      // );
    },
  },

  created() {
    localStorage.getItem("siteLanguage") === "GR"
      ? ((this.content = LanguageStrings.gr.JobsPage),
        (this.siteLanguage = localStorage.getItem("siteLanguage")),
        (this.Seo = SeoStrings.gr.Jobs))
      : ((this.content = LanguageStrings.en.JobsPage),
        (this.Seo = SeoStrings.en.Jobs));

    document.querySelector("head title").innerText = this.content.MetaTitle;
    axios
      .get(constant.API_URL + "get-all-jobs/" + this.siteLanguage)
      .then((response) => {
        this.loading = true;
        if (response.data.data.length) {
          this.jobs = response.data.data;
          this.dublicatejobs = response.data.data;

          let dubOffices = [];

          this.jobs.forEach((sin) => {
            if (!dubOffices.length) {
              let obj = {
                office: sin.office,
              };
              dubOffices.push(obj);
            } else {
              let findOffice = dubOffices.find(
                (single) => single.office.id === sin.office.id
              );
              if (!findOffice) {
                let obj = {
                  office: sin.office,
                };
                dubOffices.push(obj);
              }
            }
          });

          this.offices = dubOffices;

          /*
          this.offices =
            localStorage.getItem("siteLanguage") === "EN"
              ? dubOffices
              : dubOffices.filter((item) => item.office.office_code !== "PK");

          this.jobs =
            localStorage.getItem("siteLanguage") === "EN"
              ? this.jobs
              : this.jobs.filter((item) => item.office.office_code !== "PK");
          */

          let dubDept = [];
          this.jobs.forEach((sin) => {
            if (!dubDept.length) {
              let obj = {
                dept: sin.department,
              };
              dubDept.push(obj);
            } else {
              let findDept = dubDept.find(
                (single) => single.dept.id === sin.department.id
              );
              if (!findDept) {
                let obj = {
                  dept: sin.department,
                };
                dubDept.push(obj);
              }
            }
          });
          this.dept = dubDept;
          this.loading = false;
        } else {
          this.err = response.data.message;
          this.loading = false;
        }

        this.loading = false;
      })
      .catch(() => {
        this.err = this.content.ApiError;
        this.loading = false;
      })
      .finally(() => (this.loading = false));
  },
  mounted() {
    window.scrollTo(0, 0);
    document.querySelector(".pos-desc").addEventListener("scroll", (e) => {
      let scrollTop = e.target.scrollTop;
      console.log(scrollTop);

      // if (scrollTop >= 0 && scrollTop < 150) {
      //   this.activePosDescIndex = 0;
      // } else if (scrollTop < 362) {
      //   this.activePosDescIndex = 1;
      // } else if (scrollTop < 500) {
      //   this.activePosDescIndex = 2;
      // } else if (scrollTop < 750) {
      //   this.activePosDescIndex = 3;
      // } else if (scrollTop < 930) {
      //   this.activePosDescIndex = 4;
      // } else if (scrollTop < 1150) {
      //   this.activePosDescIndex = 5;
      // } else if (scrollTop < 1228) {
      //   this.activePosDescIndex = 6;
      // } else {
      //   this.activePosDescIndex = 7;
      // }

      if (scrollTop >= 0 && scrollTop < this.posDescItems[1].offsetTop - 100) {
        this.activePosDescIndex = 0;
      } else if (scrollTop < this.posDescItems[2].offsetTop - 100) {
        this.activePosDescIndex = 1;
      } else if (scrollTop < this.posDescItems[3].offsetTop - 100) {
        this.activePosDescIndex = 2;
      } else if (scrollTop < this.posDescItems[4].offsetTop - 100) {
        this.activePosDescIndex = 3;
      } else if (scrollTop < this.posDescItems[5].offsetTop - 100) {
        this.activePosDescIndex = 4;
      } else if (scrollTop < this.posDescItems[6].offsetTop - 300) {
        this.activePosDescIndex = 5;
      } else if (scrollTop < this.posDescItems[7].offsetTop - 450) {
        this.activePosDescIndex = 6;
      } else {
        this.activePosDescIndex = 7;
      }
    });

    let posDescs = document.querySelectorAll(".pos-desc .desc-inner");
    this.posDescItems = posDescs;
    posDescs.forEach((item) => console.log(item.offsetTop));
  },

  computed() {},
};
</script>
